import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import {
  ContentType,
  RAFShiftActionStatus,
} from "../../../../constants/Common/Constants";
import { getServiceTransactionTitleByStartEndDate } from "../ServiceTransactionHelper";
import { ServiceTransactionTemplateRow } from "./ServiceTransactionTemplateRow";

export const getServiceTransactionListTemplate = (relatedToUID?: string) => {
  return new Promise<ServiceTransactionTemplateRow[]>((resolve) => {
    let listServiceRequest = new ListServiceRequest();

    if (isNotNullAndUndefined(relatedToUID)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(relatedToUID);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ServiceTransactionTemplateRow>("RelatedToUID");
      relatedFilter.Rules.push(filter1);

      listServiceRequest.CustomFilter = relatedFilter;
    }

    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/ListTemplate",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export function saveServiceTransactionTemplate(
  serviceTransactionTemplateRow: ServiceTransactionTemplateRow
) {
  return new Promise<ServiceTransactionTemplateRow>((resolve) => {
    let serviceTransactionTemplateToSubmit = {
      ...serviceTransactionTemplateRow,
    };

    if (
      serviceTransactionTemplateToSubmit.RelatedToUID === null ||
      serviceTransactionTemplateToSubmit.RelatedToType === null
    ) {
      serviceTransactionTemplateToSubmit.RelatedToType = null;
      serviceTransactionTemplateToSubmit.RelatedToUID = null;
      serviceTransactionTemplateToSubmit.RelatedTo = null;
    }

    if (
      isNotNullAndUndefined(serviceTransactionTemplateToSubmit.Priority) &&
      serviceTransactionTemplateToSubmit.Priority === "Normal"
    ) {
      serviceTransactionTemplateToSubmit.Priority = null;
    }

    const URL = "ServiceTransaction/SaveTemplate";

    repositoryActions
      .postDataAndGetResponse(
        URL,
        getSaveRequest(
          serviceTransactionTemplateToSubmit,
          serviceTransactionTemplateToSubmit.UID
        ),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          serviceTransactionTemplateRow.Title = response.data.ObjectName;
          serviceTransactionTemplateRow.UID = response.data.EntityId;
          resolve(serviceTransactionTemplateRow);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
}

export const GenerateScheduleServiceTransaction = (
  generateScheduleRequest: ServiceTransactionTemplateRow
) => {
  return new Promise<ServiceTransactionTemplateRow[]>((resolve) => {
    if (isNotNullAndUndefined(generateScheduleRequest)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/GenerateSchedule",
          generateScheduleRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            const responseData = response.data.Entities;

            const generatedServiceTransactionList = responseData.map(
              (item, index) => ({
                ...item,
                Title: getServiceTransactionTitleByStartEndDate(
                  item.StartDate ?? new Date(),
                  item.EndDate
                ),
                Duration: getTimeDifferenceInMinutes(
                  new Date(item.StartDate),
                  new Date(item.EndDate)
                ),
                Status: isNotNullAndUndefined(item.AssigneeUID)
                  ? RAFShiftActionStatus.Scheduled
                  : RAFShiftActionStatus.Unassigned,
                BillingStatus: RAFShiftActionStatus.NotReady,
              })
            );

            resolve(generatedServiceTransactionList);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const GenerateGenerateMultiShifts = (
  generateScheduleRequest: ServiceTransactionTemplateRow
) => {
  return new Promise<ServiceTransactionTemplateRow[]>((resolve) => {
    if (isNotNullAndUndefined(generateScheduleRequest)) {
      repositoryActions
        .postDataAndGetResponse(
          "ServiceTransaction/GenerateMultiShifts",
          getSaveRequest(generateScheduleRequest, null),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            const responseData = response.data.Entities;

            const generatedServiceTransactionList = responseData.map(
              (item, index) => ({
                ...item,
                Title: getServiceTransactionTitleByStartEndDate(
                  item.StartDate ?? new Date(),
                  item.EndDate
                ),
                Duration: getTimeDifferenceInMinutes(
                  new Date(item.StartDate),
                  new Date(item.EndDate)
                ),
                Status: isNotNullAndUndefined(item.AssigneeUID)
                  ? RAFShiftActionStatus.Scheduled
                  : RAFShiftActionStatus.Unassigned,
                BillingStatus: RAFShiftActionStatus.NotReady,
              })
            );

            resolve(generatedServiceTransactionList);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getServiceTransactionTemplate = (recordIds: string) => {
  return new Promise<ServiceTransactionTemplateRow>((resolve) => {
    return repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/RetrieveTemplate",
        {
          EntityId: recordIds,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else if (
            response.data &&
            response.data.ResultTable &&
            response.data.ResultTable.length > 0
          ) {
            resolve(response.data.ResultTable[0]);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
  });
};

export const getTemplateByServiceContractItemId = (contractItemId?: string) => {
  return new Promise<ServiceTransactionTemplateRow[]>((resolve) => {
    let listServiceRequest = new ListServiceRequest();

    if (isNotNullAndUndefined(contractItemId)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(contractItemId);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ServiceTransactionTemplateRow>(
        "ServiceContractItemUID"
      );
      relatedFilter.Rules.push(filter1);

      listServiceRequest.CustomFilter = relatedFilter;
    }

    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/ListTemplate",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const getRelatedTemplateByRelatedIdAndContractItemId = (
  contractItemId?: string,
  relatedToUID?: string
) => {
  return new Promise<ServiceTransactionTemplateRow[]>((resolve) => {
    let listServiceRequest = new ListServiceRequest();

    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    if (isNotNullAndUndefined(contractItemId)) {
      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push(contractItemId);
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = propertyOf<ServiceTransactionTemplateRow>(
        "ServiceContractItemUID"
      );
      relatedFilter.Rules.push(filter1);
    }

    if (isNotNullAndUndefined(relatedToUID)) {
      let filter2: RAFCustomFilter = {};
      let filterVal2: string[] = [];
      filterVal2.push(relatedToUID);
      filter2.Operator = RAFCustomOperator.Equal;
      filter2.Value = filterVal2;
      filter2.Field = propertyOf<ServiceTransactionTemplateRow>("RelatedToUID");
      relatedFilter.Rules.push(filter2);
    }

    listServiceRequest.CustomFilter = relatedFilter;

    repositoryActions
      .postDataAndGetResponse(
        "ServiceTransaction/ListTemplate",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => resolve(null));
  });
};

export const getScheduleTemplateCustomFilter = (
  pricelistItemId?: string,
  relatedToUID?: string
) => {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  if (IsNotNullOrWhiteSpace(pricelistItemId)) {
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(pricelistItemId);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = "ServiceContractItemUID";
    relatedFilter.Rules.push(filter1);
  }
  if (IsNotNullOrWhiteSpace(relatedToUID)) {
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(relatedToUID);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = "RelatedToUID";
    relatedFilter.Rules.push(filter1);
  }

  return relatedFilter;
};
